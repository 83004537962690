import firebase from 'firebase';
import { SensorAlerts, SensorProblems, SensorRefill, SensorSupplies } from '../../microservices/sensors';

export const alertColours = {
    critical: '#E1AF00FF', empty: '#980000FF', normal: '#007209FF', outOfRange: 'rebeccapurple',
};

export interface SensorBarLevel {
    max: number;
    now: number;
    current: number;
    percentage: number;
    outOfRange?: boolean;
}

export interface SensorSettings {
    device: string;
    type: string;
}

export interface Sensor {
    CloudRegion: string;
    Firmware: string;
    Gateway: string;
    Hardware: string;
    Registry: string;
    Serial: string;
    Type: string;
    Connection: string;
    accessInfo: string;
    city: string;
    contractType: string;
    district: string;
    id: string;
    pin: string;
    sensorClaimedAt: firebase.firestore.Timestamp;
    lastReportedTimestamp: firebase.firestore.Timestamp;
    messageCenter: SensorMessageCenterContact[];
    location: string;
    coordinates: Coordinates;
    name: string;
    label: string;
    tags: string[];
    phone: string;
    problems: SensorProblems[];
    reportPhone: string;
    supplies: SensorSupplies;
    type: string;
    containerType: string;
    refill: SensorRefill;
    alerts: SensorAlerts;
    reportingFrequency?: number;
    reportingTime?: string;
    logs: any[];
    users: string[];
    settings: SensorSettings;
    WdId?: number;
}

export interface SensorMessageCenterContact {
    email: string;
    warning: boolean;
    empty: boolean;
}

export interface SensorExport {
    id?: number;
    name?: string;
    tags?: string[];
    type?: string;
    Serial?: string;
    AllowedCom?: string;
    Distance?: number;
    CurrentVoltage?: number;
    Timestamp?: string;
    Latitude?: string;
    Longitude?: string;
}

export interface SensorStatus {
    Serial?: string;
    AllowedCom?: string;
    Timestamp?: firebase.firestore.Timestamp;
    Battery?: string;
    doorStatus?: number;
    doorOpenDuration?: number;
    doorOpenTime?: number;
    CurrentVoltage?: number;
    Firmware?: string;
    Hardware?: string;
    TotalDoses?: string;
    Distance?: number;
    Lux?: number;
    CounterA?: number;
    CounterB?: number;
    TotalCounterA?: number;
    TotalCounterB?: number;
    PayloadCounter?: number;
    DeviceStatus?: string;
    SensorStatus?: string;
    UsedCharge?: number;
    InternalTemperature?: number;
    ButtonConfig?: string;
    ConfigFlags?: string;
    StatusMessageInterval?: number;
    GitHash?: string;
    ButtonId?: string;
    ButtonCount?: number;
}

export interface SensorLog {
    id: string;
    station: string;
    deviceID: string;
    type: string;
    createdAt: firebase.firestore.Timestamp;
    resolvedAt: firebase.firestore.Timestamp;
    createdBy: {
        username: string;
        userID: string;
    },
    resolvedBy: {
        username: string;
        userID: string;
    },
    maintenance: {
        name: string;
        description: string;
        comments: string;
    } | string;
}

export interface Coordinates {
    x: Number | null;
    y: Number | null;
    wkid: Number | null;
}


export enum SensorTypes {
    DOSES = 'doses',
    DISTANCE = 'distance',
    COUNTER = 'counter',
    BOOL = 'boolean',
    WINDOW = 'window',
    MILESIGHT = 'milesight'
}

export const sensorTypeMapping: any = {
    'ibiot-distance': SensorTypes.DISTANCE,
    'imb-type2-variant6-people-counter': SensorTypes.COUNTER,
    'miromico-fmlr-iot-button': SensorTypes.COUNTER,
    'unknown': SensorTypes.DOSES,
    'dragino': SensorTypes.DISTANCE,
    'nbiot-distance': SensorTypes.DISTANCE,
    //'milesight': SensorTypes.DISTANCE,
    'window': SensorTypes.WINDOW,
    'ibiot-tof': SensorTypes.DISTANCE,
    'milesight': SensorTypes.MILESIGHT,
    'ultrasonic': SensorTypes.DISTANCE,
};

export const sensorTypeContainerMapping: any = {
    'paper-feeder': SensorTypes.DISTANCE,
    'recycling-bin': SensorTypes.DISTANCE,
    'window-sensor': SensorTypes.BOOL,
};

