import {SensorAlertType} from "../../microservices/sensors";
import {alertColours} from "../../models/types/sensor";

interface Props {
    percentage: number;
    label: string;
    alerts?: SensorAlertType;
    max?: number;
    icon?: any;
    outOfRange?: boolean;
    reverse?: boolean;
    showAlerts? : boolean;
}

export function UiProgressBarVertical({percentage, label, alerts = {critical: 0, empty: 0}, max = 100, icon, outOfRange, reverse, showAlerts}: Props) {

    const alertPercentage = {
        empty: alerts.empty / (max / 100),
        critical: alerts?.critical / (max / 100),
    }

    if (reverse) {
        alertPercentage.empty = 100 - alertPercentage.empty;
        alertPercentage.critical = 100 - alertPercentage.critical;
    }

    let barBackgroundColor = alertColours.normal;

    if ((reverse && alertPercentage.empty < percentage) || (!reverse && alertPercentage.empty > percentage)) {
        barBackgroundColor = alertColours.empty;
    } else if ((reverse && alertPercentage.critical < percentage) || (!reverse && alertPercentage.critical > percentage)) {
        barBackgroundColor = alertColours.critical;
    }

    const alertsEmpty = {
        backgroundColor: alertColours.empty,
        height: '2px',
        width: '100%',
        bottom: `${alertPercentage.empty}%`,
        position: 'absolute' as 'absolute',
        zIndex: 2,
    };

    const alertsCritical = {
        backgroundColor: alertColours.critical,
        height: '2px',
        width: '100%',
        bottom: `${alertPercentage.critical}%`,
        position: 'absolute' as 'absolute',
        zIndex: 2,
    };

    const alertsLabel = {
        position: 'absolute' as 'absolute',
        fontSize: '0.5rem',
        top: '-10px',
        left: '1px',
    };

    if (outOfRange) {
        barBackgroundColor = alertColours.outOfRange;
    }

    return <>
                <div style={{
                    padding: 4, color: '#000000', fontSize: '0.8rem',
                }}>{label}</div>
                <div style={{ width: '100%', position: 'relative' }}>
                    <div style={{ width: '100%', height: '100%', position: 'relative', }}>
                        {outOfRange &&
                            <div style={{
                                width: '100%',
                                color: '#ffffff',
                                position: 'absolute',
                                top: '60px',
                                textAlign: 'center',
                                zIndex: 1,
                                fontWeight: 'bold',
                                fontSize: '1.5rem',
                                textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
                            }}>Out of<br/> range</div>
                        }
                        <div style={{
                            width: '100%',
                            backgroundColor: outOfRange ? alertColours.outOfRange : "#949494",
                            position: 'absolute',
                            top: 0,
                            bottom: '4px',
                        }}>
                        </div>
                        <div style={{
                            width: '100%',
                            top: '7px',
                            bottom: '7px',
                            position: 'absolute',
                        }}>
                            <div style={{
                                height: `${percentage > 100 ? 100 : percentage}%`,
                                width: '100%',
                                backgroundColor: barBackgroundColor,
                                position: 'absolute',
                                bottom: 0,
                            }}>
                            </div>
                            {showAlerts ? <>
                                {alerts?.empty && <div style={alertsEmpty}>
                                    <span style={{ ...alertsLabel, color: alertColours.empty }}>{alerts.empty}</span>
                                </div>}
                                {alerts?.critical && <div style={alertsCritical}>
                                    <span style={{ ...alertsLabel, color: alertColours.critical }}>{alerts.critical}</span>
                                </div>}
                            </> : <></>}
                            
                        </div>
                        <img src={icon} alt=".." style={{ width: '100%', position: 'relative' }} />
                    </div>
                </div>
            </>;
}