import moment from "moment";
import firebase from "firebase";
import {alertColours, Sensor, SensorBarLevel, SensorStatus} from "../models/types/sensor";

export async function fixSensorRefill(sensor: Sensor): Promise<void> {
    return;
/*
    const db = firebase.firestore();

    const statuses = await db
        .collection(`devices/${sensor.id}/status`)
        .where('Timestamp', '>', sensor.refill.refillAt)
        .orderBy('Timestamp', 'desc')
        .get();

    let lastDose = sensor.refill.doses;
    const currentSensor = { ...sensor };
    statuses.forEach(doc => {
        if (Number(lastDose) < Number(doc.data().TotalDoses)) {
            const newRefill = lastDose - Number(doc.data().TotalDoses) - Number(currentSensor.refill.doses)
            currentSensor.refill.doses = newRefill;
        }
        lastDose = doc.data().TotalDoses;
    });

    await db.collection('devices').doc(sensor.id).update({
        refill: {
            doses: currentSensor.refill.doses,
            refillAt: sensor.refill.refillAt,
        }
    });
*/
}

export function calculateSensorDistance(sensor: Sensor, sensorStatus: SensorStatus): SensorBarLevel {
    const max = Number(sensor?.supplies?.maxLiquid) || 0;
    const now = (Number(sensorStatus?.Distance)) || 0;
    const current = max - now;
    const percentage = Math.round(current / max * 100);

    return { max, now, current, percentage};
}

export function calculateSensorLiquidLevel(sensor: Sensor, sensorStatus: SensorStatus): SensorBarLevel {
    const max = Number(sensor?.supplies?.maxLiquid) || 0;
    const now = (Number(sensorStatus?.TotalDoses) - Number(sensor?.refill?.doses)) || 0;
    const current = max - now;
    const percentage = !sensor?.refill?.doses || max === 0 ? -1 : Math.round(current / max * 100);

    return { max, now, current, percentage};
}

export interface TimeToGo {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export function calculateBatteryPercentage(sensor: Sensor, sensorStatus: SensorStatus, max: number = 5.2): number {
    if(sensor.containerType === 'window-sensor'){
        return sensorStatus.Distance ? 100 : 0;
    }

    if (sensor.Connection === "wastedigital") {
        return (parseFloat(sensorStatus.Battery) / 1000) / max * 100;
    }

    if (sensorStatus?.CurrentVoltage) {
        return (sensorStatus.CurrentVoltage / 5) * 100;
    }
    return (sensorStatus?.Battery === 'Ok') ? 100 : 10
}

export function calculateSecondsLeftDistance(sensor: Sensor, sensorStatus: SensorStatus): TimeToGo {
    try {
        const refilledAtTime = sensor?.refill?.refillAt;
        if (!refilledAtTime) {
            return { minutes: -1, hours: -1, seconds: -1, days: -1 };
        }

        const maxLiquid = sensor?.supplies?.maxLiquid;

        if (!maxLiquid) {
            return { minutes: -1, hours: -1, seconds: -1, days: -1 };
        }

        const timeBetweenRefill = moment.duration(moment().diff(refilledAtTime.toDate())).asSeconds();
        const timeToGo = Number(maxLiquid) / Number(sensorStatus.Distance) * timeBetweenRefill;
        const days = Math.floor(timeToGo / (60 * 60 * 24));
        const hours = Math.floor((timeToGo - (days * 60 * 60 * 24)) / (60 * 60));
        const minutes = Math.floor((timeToGo - (days * 60 * 60 * 24) - (hours * 60 * 60)) / 60);
        const seconds = Math.floor((timeToGo - (days * 60 * 60 * 24) -  (hours * 60 * 60) - (minutes * 60)))

        if (days >= 0 && hours >= 0 && minutes >= 0 && seconds >= 0) {
            return { days, hours, minutes, seconds };
        }

        return { minutes: -1, hours: -1, seconds: -1, days: -1 };
    } catch(error) {
        return { minutes: -1, hours: -1, seconds: -1, days: -1 };
    }
}

export function calculateSecondsLeft(sensor: Sensor, sensorStatus: SensorStatus): TimeToGo {
    try {
        const refilledAtTime = sensor?.refill?.refillAt;
        const refilledAtDoses = sensor?.refill?.doses;
        if (!refilledAtTime || !refilledAtDoses) {
            return { minutes: -1, hours: -1, seconds: -1, days: -1 };
        }

        const maxLiquid = sensor?.supplies?.maxLiquid;
        if (!maxLiquid) {
            return { minutes: -1, hours: -1, seconds: -1, days: -1 };
        }

        const totalDoses = sensorStatus?.TotalDoses;
        if (!totalDoses) {
            return { minutes: -1, hours: -1, seconds: -1, days: -1 };
        }

            const usedDosesAfterRefill = Number(totalDoses) - Number(refilledAtDoses);
        const timeBetweenRefill = moment.duration(moment().diff(refilledAtTime.toDate())).asSeconds();
        const timeToGo = Number(maxLiquid) / usedDosesAfterRefill * timeBetweenRefill;
        const days = Math.floor(timeToGo / (60 * 60 * 24));
        const hours = Math.floor((timeToGo - (days * 60 * 60 * 24)) / (60 * 60));
        const minutes = Math.floor((timeToGo - (days * 60 * 60 * 24) - (hours * 60 * 60)) / 60);
        const seconds = Math.floor((timeToGo - (days * 60 * 60 * 24) -  (hours * 60 * 60) - (minutes * 60)))

        if (days >= 0 && hours >= 0 && minutes >= 0 && seconds >= 0) {
            return { days, hours, minutes, seconds };
        }

        return { minutes: -1, hours: -1, seconds: -1, days: -1 };
    } catch(error) {
        return { minutes: -1, hours: -1, seconds: -1, days: -1 };
    }
}


export async function insertSensorLog(sensorId: string, log: any): Promise<void> {
    const db = firebase.firestore();
    await db.collection(`devices/${sensorId}/logs`).add(log);
}


export function calculateBarMessageColor(sensor: Sensor, barLevel: SensorBarLevel): string {
    if (!sensor) {
        return alertColours.normal;
    }

    if (sensor.containerType === 'recycling-bin') {
        if (sensor && sensor.alerts && sensor.alerts.doses && sensor.alerts.doses.empty < barLevel.percentage) {
            return alertColours.empty;
        }
        if (sensor && sensor.alerts && sensor.alerts.doses && sensor.alerts.doses.critical < barLevel.percentage) {
            return alertColours.critical;
        }
    } else {
        if (sensor && sensor.alerts && sensor.alerts.doses && sensor.alerts.doses.empty > barLevel.percentage) {
            return alertColours.empty;
        }
        if (sensor && sensor.alerts && sensor.alerts.doses && sensor.alerts.doses.critical > barLevel.percentage) {
            return alertColours.critical;
        }
    }
    return alertColours.normal;
}
