import React from "react";
import {Grid} from "@mui/material";
import {SensorTableRow} from "./row/SensorTableRow";
import {UiBox} from "../../ui/UiBox";
import {Sensor, sensorTypeMapping, SensorTypes} from "../../../models/types/sensor";

interface Props {
    sensors: Sensor[];
    setTags: (tag: string[]) => void;
}

export function SensorsTable({sensors, setTags}: Props) {
    sensors.map(sensor => {
        console.log(sensor.id, sensor.Type, sensorTypeMapping[sensor.Type]);
    });

    return (<>
        <UiBox header="Sensor list - distance and doses" closeable>
            <div style={{ height: '350px', overflowY: 'scroll' }}>
                <Grid container sx={{ display: { lg: 'flex', md: 'none', xs: 'none' } }} style={{ position: 'sticky', top: '0', backgroundColor: '#ffffff',zIndex:2 }}>
                    <Grid item lg={2} padding={1}><strong>SENSOR NAME</strong></Grid>
                    <Grid item lg={1.5} padding={1}><strong>CONSUMABLE</strong></Grid>
                    <Grid item lg={1.5} padding={1}><strong>BATTERY</strong></Grid>
                    <Grid item lg={2} padding={1}><strong>LAST CONNECTION</strong></Grid>
                    <Grid item lg={3} padding={1}><strong>LOCATION TAGS</strong></Grid>
                    <Grid item lg={2} padding={1} style={{ textAlign: 'right' }}><strong>EDIT/RESET</strong></Grid>
                </Grid>
                <Grid container style={{ marginTop: '10px' }}>
                    {sensors.map((sensor) => {
                        if (!(!sensor.Type || [SensorTypes.DOSES, SensorTypes.DISTANCE, SensorTypes.MILESIGHT].includes(sensorTypeMapping[sensor.Type]))) {
                            return <div key={`distance-sensor-${sensor.id}`}></div>;
                        }
                        return <SensorTableRow
                            key={`distance-sensor-${sensor.id}`}
                            sensor={sensor}
                            setTags={setTags}
                        />
                    })}
                </Grid>
            </div>
        </UiBox>
        <UiBox header="Sensor list - people counter" closeable>
            <div style={{ height: '350px', overflowY: 'scroll' }}>
                <Grid container sx={{ display: { lg: 'flex', md: 'none', xs: 'none' } }} style={{ position: 'sticky', top: '0', backgroundColor: '#ffffff',zIndex:2 }}>
                    <Grid item lg={2} padding={1}><strong>SENSOR NAME</strong></Grid>
                    <Grid item lg={1.5} padding={1}><strong>COUNT</strong></Grid>
                    <Grid item lg={1.5} padding={1}><strong>BATTERY</strong></Grid>
                    <Grid item lg={2} padding={1}><strong>LAST CONNECTION</strong></Grid>
                    <Grid item lg={3} padding={1}><strong>LOCATION TAGS</strong></Grid>
                    <Grid item lg={2} padding={1} style={{ textAlign: 'right' }}><strong>EDIT/RESET</strong></Grid>
                </Grid>
                <Grid container style={{ marginTop: '10px' }}>
                    {sensors.map((sensor, index) => {
                        if (!(sensor.Type && sensorTypeMapping[sensor.Type] === SensorTypes.COUNTER)) {
                            return <div key={`counter-sensor-${sensor.id}`}></div>;
                        }
                        return <SensorTableRow
                            key={`counter-sensor-${sensor.id}`}
                            sensor={sensor}
                            setTags={setTags}
                        />
                    })}
                </Grid>
            </div>
        </UiBox>
        <UiBox header="Sensor list - Window Sensors" closeable>
            <div style={{ height: '350px', overflowY: 'scroll' }}>
                <Grid container sx={{ display: { lg: 'flex', md: 'none', xs: 'none' } }} style={{ position: 'sticky', top: '0', backgroundColor: '#ffffff',zIndex:2 }}>
                    <Grid item lg={2} padding={1}><strong>SENSOR NAME</strong></Grid>
                    <Grid item sx={{textAlign:"center"}} lg={3} padding={1}><strong>LEVEL</strong></Grid>
                    <Grid item lg={2} padding={1}><strong>LAST CONNECTION</strong></Grid>
                    <Grid item lg={3} padding={1}><strong>LOCATION TAGS</strong></Grid>
                    <Grid item lg={2} padding={1} style={{ textAlign: 'right' }}><strong>EDIT/RESET</strong></Grid>
                </Grid>
                <Grid container style={{ marginTop: '10px' }}>
                    {sensors.map((sensor, index) => {
                        if (!(sensor.Type && sensorTypeMapping[sensor.Type] === SensorTypes.WINDOW)) {
                            return <div key={`counter-sensor-${sensor.id}`}></div>;
                        }
                        return <SensorTableRow
                            key={`counter-sensor-${sensor.id}`}
                            sensor={sensor}
                            setTags={setTags}
                        />
                    })}
                </Grid>
            </div>
        </UiBox>

    </>
    );
}